<template>
  <div>
    <page-header>
      <template v-slot:title>
        {{ title }}
      </template>
    </page-header>
    <v-container fluid ma-0 pa-0 fill-height style="height: 100vh !important" id="surveyCreator"> </v-container>
  </div>
</template>
<script>
import {
  Serializer,
} from "survey-core";
import { SurveyLogic, SurveyCreator } from "survey-creator-knockout";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { localization } from "survey-creator-core";

export default {
  name: "survey-creator",
  props: {
    settings: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    title: {
      type: String,
      default: null,
    },
    defaultSurvey: {
      type: [Object, Array],
      default: () => {
        return {
          pages: [
            {
              name: "Name",
              elements: [],
            },
          ],
        };
      },
    },
    surveyConfiguration: [],
  },
  data() {
    return {};
  },
  mounted() {
    // Removed because this is clearing up Survey Form
    // if(this.title != "") {
    //     localStorage.removeItem(this.title);
    // }
    const creator = new SurveyCreator({
      isAutoSave: true,
      showJSONEditorTab: false,
      showSurveyTitle: false,
      haveCommercialLicense: true,
      showLogicTab: true,
      clearInvisibleValues: "none",
    });

    // Add property
    Serializer.addProperty("question", {
      name: "cdc:condition",
      category: "logic",
    });

    // Add logic type
    const cdcLoaded = SurveyLogic.types.some(item => item.name === 'question_cdc');

    if (!cdcLoaded) {
      SurveyLogic.types.push({
        name: "question_cdc",
        baseClass: "question",
        propertyName: "cdc",
        showIf: function (survey) {
          return survey.getAllQuestions().length > 0;
        },
      });

      // Set translations
      const translations = localization.getLocale("");
      translations.ed.lg.question_cdcName = "Set as CDC";
      translations.ed.lg.question_cdcText = "set question as CDC";
      translations.ed.lg.question_cdcDescription =
        "Make the question tagged as CDC.";
    }

    creator.clearInvisibleValues = "none";

    creator.toolbox.forceCompact = false;
    // Get localstorage survey-json or defaultJSON values
    let jsonConfig =
      window.localStorage.getItem(this.title) || this.surveyConfiguration;

    if (jsonConfig) {
      jsonConfig = JSON.parse(jsonConfig);
      jsonConfig["clearInvisibleValues"] = "none";
      jsonConfig = JSON.stringify(jsonConfig);
      creator.text = jsonConfig;
    }

    // creator.text = window.localStorage.getItem(this.title) || JSON.stringify(this.surveyConfiguration);
    creator.saveSurveyFunc = (saveNo, callback) => {
      window.localStorage.setItem(this.title, creator.text);
      // callback(saveNo, true);
      // console.log(callback(saveNo, true));
      // saveSurveyJson(
      //     "https://your-web-service.com/",
      //     creator.JSON,
      //     saveNo,
      //     callback
      // );
    };

    creator.render("surveyCreator");
  },
};
</script>
<style >
.svc-notifier {
  position: absolute;
  top: 70px;
  width: 120px;
  height: 30px;
  background-color: gray;
  color: white;
  font-weight: bold;
}
.survey-creator *,
.svc-creator * {
  font-family: var(--font-family-base) !important;
}
.svc-logic-tab__content.svc-logic-tab__empty {
  position: initial !important;
}
</style>
